<template>
    <div class="mine">
        <!-- 自定义头部 -->
        <div class="header">
            <img src="@/assets/yq/mine-header.png" class="header-bg" />
            <div class="userInfo-box flex">
                <div class="icon">
                    <img :src="icon" />
                </div>
                <!-- <div class="not-login color-fff" v-if="isLogin">登录/注册</div> -->
                <div class="is-login" v-if="isLogin" @click="goUserEdit">
                    <div class="nickname color-fff">
                        {{ userInfo.name }}
                    </div>
                    <div class="phone-opt flex">
                        <div class="phone color-fff">
                            {{ userInfo.mobile }}
                        </div>
                        <!-- <div class="opt">
                            <img src="@/assets/yq/mine-user-edit.png" />
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
        <!-- 我的认证 -->
        <div class="mine-auth bg-fff">
            <mine-gn-item
                v-for="(item, index) in authItem"
                :key="index"
                :title="item.title"
                :icon="item.icon"
                :url="item.url"
                :desc="item.desc"
            />
        </div>
        <div class="mine-complaint bg-fff">
            <mine-gn-item
                v-for="(item, index) in complaintItem"
                :key="index"
                :title="item.title"
                :icon="item.icon"
                :url="item.url"
                :desc="item.desc"
            />
        </div>
        <div class="btn" @click="loginOut">
            <zlButton text="退出登录" class="btn_chil" />
        </div>
    </div>
</template>

<script>
import mineGnItem from "@/components/mine/mineGnItem.vue";
import { getUserInfo } from "@/api/user";
import { getUserinfo , setUserinfo } from "@/utils/auth";
import zlButton from "@/components/zlButton.vue";
import { Dialog } from 'vant';
export default {
    components: {
        mineGnItem,
        zlButton
    },
    data() {
        return {
            title: "",
            backVisible: false,
            isLogin: true,
            icon: require("@/assets/yq/not-login-icon.png"),
            authItem: [
                {
                    title: "客服电话",
                    icon: require("@/assets/yq/mine-gn-05.png"),
                    desc: "",
                    url: "",
                },
                // {
                //     title: "个人身份认证",
                //     icon: require("@/assets/yq/mine-gn-01.png"),
                //     desc: "",
                //     url: "/authMain",
                // },
                // {
                //     title: "我的证书",
                //     icon: require("@/assets/yq/mine-gn-02.png"),
                //     desc: "",
                //     url: "/certificate",
                // },
            ],
            complaintItem: [
                // {
                //     title: "我的投诉",
                //     icon: require("@/assets/yq/mine-gn-03.png"),
                //     desc: "",
                //     url: "/complaint",
                // },
                // {
                //     title: "意见反馈",
                //     icon: require("@/assets/yq/mine-gn-04.png"),
                //     desc: "",
                //     url: "/opinion",
                // },
                // {
                //     title: "客服电话",
                //     icon: require("@/assets/yq/mine-gn-05.png"),
                //     desc: "",
                //     url: "",
                // },
            ],
            userInfo: {},
            firstLoad: false,
        };
    },
    methods: {
        goUserEdit() {
            return;
            // this.$router.push({
            //     path: "/userInfo"
            // })
        },
        loginOut() {
            Dialog.confirm({
				title: '提示',
				message: '确定退出吗~',
			})
			.then(() => {
				this.$store.dispatch('user/loginOut');
				this.$router.replace({
					path: "/login"
				})
			})
        }
    },
    created() {

        //判断用户信息
        if (!getUserinfo()) {
            getUserInfo().then((res) => {
                let { code, data } = res;
                console.log(res);
                if ( code === 0 ) {
                    this.userInfo = data;
                    setUserinfo(JSON.stringify(data));
                }
            });
        } else {
            this.userInfo = JSON.parse(getUserinfo());
            console.log(this.userInfo);
        }
    },

};
</script>

<style lang="scss" scoped>
page {
    background: #fff;
}
div {
    box-sizing: border-box;
}
.mine {
    /* height: 263.5px; */
}
.mine .header {
    height: 243.5px;
    width: 100%;
    padding-top: 60px;
    padding-left: 20px;
}
.mine .header .header-bg {
    position: absolute;
    height: 243.5px;
    width: 100%;
    top: 0;
    left: 0;
}
.mine .userInfo-box {
    position: relative;
    height: 64px;
    width: 100%;
}
.mine .userInfo-box .icon img {
    width: 64px;
    height: 64px;
}
.mine .userInfo-box .not-login,
.is-login {
    margin-left: 16px;
    height: 64px;
}
.mine .userInfo-box .not-login {
    font-size: 18px;
    line-height: 64px;
}
.mine .userInfo-box .is-login {
    width: 100%;
    padding-top: 11px;
}
.mine .is-login .nickname {
    font-size: 21px;
}
.mine .is-login .phone-opt {
    height: 20px;
}
.mine .is-login .phone-opt .phone {
    padding-top: 6px;
    font-size: 12px;
}
.mine .is-login .phone-opt .opt {
    margin-left: 22px;
}
.mine .is-login .phone-opt .opt img {
    width: 16px;
    height: 16px;
    margin-top: 5px;
}
.mine .mine-auth {
    position: relative;
    width: 100%;
    /* height: 151px; */
    border-radius: 25px 25px 0 0;
    margin-top: -60px;
}
.mine .mine-complaint {
    /* height: 216.5px; */
    width: 100%;
    /* margin-top: 10px;
    padding-bottom: 20px; */
}
.btn {
    position: fixed;
    bottom: 80px;
    width: 100%;
    display: flex;
    justify-content: center;
    .btn_chil {
        width:90%;
    }
}

</style>
