<template>
    <div class="item-box" @click="mineNavigateTo">
        <a  :href="title=='客服电话'?'tel:' + configPhone:''" 
            :class="title=='客服电话'?'':'a-dis'"
        >
            <div class="item flex">
                <img :src="icon" alt="" />
                <span class="title">{{title}}</span>
                <div class="arrow-desc">
                    <span class="desc">{{desc}}</span>
                    <img src="@/assets/yq/mine-arrow.png" class="arrow" />
                </div>          
            </div>
            <div class="bottom-line"></div>
        </a>
    </div>
</template>

<script>
import config from "@/config/config";
export default {
    props: {
        icon: {
            type: String,
            default: ''
        },
        title:{
            type: String,
            default: ''
        },
        desc:{
            type: String,
            default: ''
        },
        url:{
            type: String,
            default: ''
        },
    },
    data() {
        return {
            configPhone: ""
        }
    },
    methods: {
        mineNavigateTo() {
            if(this.title != "客服电话"){
                this.$router.push({
                    path: this.url
                })
            }else{
                // wx.makePhoneCall({
                //     phoneNumber: config.telePhone,
                // });
                // this.phone = 'tel:' + config.telePhone;
            }
            
        },
    },
    created () {
        this.configPhone = config.telePhone;
    },
};
</script>

<style scoped>
div,a{
    box-sizing: border-box;
}

.item-box{	
    display: block;
	width: 100%;
	/* height: 75px; */
	padding: 20px 20px 0 30px;

}
.a-dis{
    pointer-events: none;
}
.item-box>a{
    display: block;
}
.mine .item-box .item{
	height: 25px;
	align-items: center;
}
.mine .item-box .item>img:nth-of-type(1){
	height: 25px;
	width: 25px;
}
.mine .item-box .item .title{
	color: #222222;
	margin-left: 7px;
	font-weight: 500;
}
.mine .item-box .item .arrow{
	margin-left: auto;
	height: 12px;
	width: 8px;
}
.mine .item-box .item .arrow-desc{
    display: block;
    margin-left: auto;
    display: flex;
    height: 25px;
	align-items: center;
}
.mine .item-box .desc{
	/* margin-left: 97px; */
	font-size: 14px;
	color: #999999;
    margin-right: 8px;
}
.mine .item-box .bottom-line{
	height: 20px;
	border-bottom: 1px solid #EDF1F4;
}  
</style>